import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"

// Temporary Data
import commonData from "../data/common-data.json"
import searchListingData from "../data/search-listing.json"

// Components
import Header from "../components/Header"
import SearchResults from "../layout/SearchResults"
import Footer from "../components/Footer"

const SearchListing = () => {

    const { Properties } = searchListingData.FeaturedProperties;

    return (
        <>
            <Seo title="Search Listing" />
            <div className="page-wrapper">
                <Header headerData={ commonData } />
                <SearchResults Properties={ Properties } />
                <Footer
                    footerData={ commonData.FooterMenu }
                    footerTermsMenu={ commonData.FooterTermsMenu }
                    footerSocialLinks={ commonData.FooterSocialLinks }
                    footerCompanies={ commonData.FooterCompanies }
                />
            </div>
        </>
    )
}

export default SearchListing
