import { navigate } from "gatsby"
import * as React from "react"

// Styles
import "./PropertyLocation.scss"

const PropertyLocation = ( { seachListingData } ) => {
  return (
    <div className="property-location">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1748.2674189526451!2d-0.42009656743627727!3d51.70699423184308!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48764015615da7cb%3A0x87285ddf2c53164b!2sAbbots%20Langley%2C%20UK!5e0!3m2!1sen!2sin!4v1641235650293!5m2!1sen!2sin"
        width="100%"
        height="100%"
        frameBorder="0"
        style={ {
          border: 0
        } }
        allowFullScreen
      />
    </div>
  )
}

export default PropertyLocation
