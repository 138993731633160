import React, { useState } from "react"
import { Container, Row, Col } from "react-bootstrap"

// Styles
import "./SearchResults.scss"

// Hooks
import useWindowDimensions from "customHooks/useWindowDimensions"

// Components
import PropertyCards from "../../components/PropertyCards"
import PropertyLocation from "../../components/PropertyLocation"
import CustomDropDown from "../../components/CustomDropdown"
import CustomCheckbox from "../../components/CustomCheckbox"
import CustomButton from "../../components/CustomButton"

const areasOptions = [
    {
        display: 'All Areas',
        value: 'All Areas'
    },
    {
        display: 'Chelsea',
        value: 'Chelsea',
    },
    {
        display: 'Cowley',
        value: 'Cowley',
    },
    {
        display: 'Jericho',
        value: 'Jericho',
    },
    {
        display: 'Chinnor',
        value: 'Chinnor',
    },
    {
        display: 'Brill',
        value: 'Brill',
    }
];

const minBedroomOptions = [
    'Studio - 1 bed',
    '2-3 beds',
    '4+ beds'
];

const priceRangeOptions = [
    '£350 – £750 p/w',
    '£750 – £1,100 p/w',
    '£1,100 p/w'
];

const featuresAmenitiesOptions = [
    'Outside Space',
    'Pet Friendly',
    'Lift',
    'Communal Garden',
    'Concierge',
    'Parking'
];

const lettingsTypeOptions = [
    {
        display: 'Lettings Type',
        value: 'Lettings Type'
    },
    {
        display: 'Long Let',
        value: 'Long Let'
    },
    {
        display: 'All Areas',
        value: 'Short Let'
    },
]

const sortOptions = [
    {
        display: 'Sort by',
        value: 'Sort by'
    }
]

const SearchResults = ( { Properties } ) => {

    const { screenWidth } = useWindowDimensions();

    const isMobileResolution = screenWidth < 768;

    const [ showFilters, setShowFilters ] = useState( isMobileResolution ? false : true );

    const [ showMapView, setShowMapView ] = useState( false );

    const [ showAdditionalFilters, setShowAdditionalFilters ] = useState( isMobileResolution ? true : false );

    return (
        <div className="search-results-wrapper">
            <div className="search-results-container">
                { isMobileResolution && (
                    <div className="search-filter-toggle-wrapper">
                        <Container>
                            <Row>
                                <Col className="search-filter-toggle-body">
                                    <span className="search-filter-toggle-heading">
                                        Filter Results
                                    </span>
                                    <span className={ `${ showFilters ? 'up-arrow' : 'down-arrow' }` }>
                                        <img
                                            src={ require( `../../images/arrow-top-icon-green.png` ).default }
                                            alt="Arrow Down"
                                            onClick={ () => setShowFilters( !showFilters ) }
                                        />
                                    </span>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                ) }
                { showFilters && (
                    <div className="search-filters">
                        <Container>
                            <Row>
                                <Col lg={ 8 }>
                                    <Row>
                                        <Col sm={ 8 } xs={ 12 }>
                                            <CustomDropDown
                                                name="area"
                                                defaultOption={ areasOptions[ 0 ] }
                                                options={ areasOptions }
                                                variant="black"
                                            // onChangeCallback={ onChange }
                                            />
                                        </Col>
                                        { !isMobileResolution && (
                                            <Col>
                                                <div
                                                    className="additional-filters"
                                                    onClick={ () => setShowAdditionalFilters( !showAdditionalFilters ) }
                                                >
                                                    <img
                                                        src={ require( `../../images/additional-filters.png` ).default }
                                                        alt="Additional Filters"
                                                    />
                                                    <span>
                                                        { !showAdditionalFilters ? 'Additional' : 'Hide' } filters
                                                    </span>
                                                </div>
                                            </Col>
                                        ) }
                                    </Row>
                                    <Row>
                                        <Col>
                                            <CustomCheckbox
                                                isInline
                                                isLabelInline
                                                name="bedroom"
                                                label="Min Bedrooms:"
                                                options={ minBedroomOptions }
                                                defaultOption={ minBedroomOptions[ 0 ] }
                                                customClass="grey-border-bottom"
                                            // onChangeCallback={ onChange }
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <CustomCheckbox
                                                isInline
                                                isLabelInline
                                                name="priceRange"
                                                label="Price range:"
                                                options={ priceRangeOptions }
                                                defaultOption={ priceRangeOptions[ 0 ] }
                                                customClass={ !showAdditionalFilters ? "pb-0 mb-0" : "grey-border-bottom" }
                                            // onChangeCallback={ onChange }
                                            />
                                        </Col>
                                    </Row>
                                    { showAdditionalFilters && (
                                        <>
                                            <Row>
                                                <Col>
                                                    <CustomCheckbox
                                                        isInline
                                                        isLabelInline
                                                        name="featuresAmenities"
                                                        label="Features and Amenities:"
                                                        options={ featuresAmenitiesOptions }
                                                        defaultOption={ featuresAmenitiesOptions[ 0 ] }
                                                        customClass="grey-border-bottom"
                                                    // onChangeCallback={ onChange }
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={ 8 }>
                                                    <CustomDropDown
                                                        name="lettingsType"
                                                        defaultOption={ lettingsTypeOptions[ 0 ] }
                                                        options={ lettingsTypeOptions }
                                                        variant="black"
                                                    // onChangeCallback={ onChange }
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <CustomButton
                                                        variant="green"
                                                        onClick={ () => {
                                                            console.log( "Button Clicked" );
                                                        } }
                                                        value="SEARCH"
                                                    />
                                                </Col>
                                            </Row>
                                        </>
                                    ) }
                                </Col>
                            </Row>
                        </Container>
                    </div>
                ) }
                <div className="search-results">
                    <Container>
                        <Row>
                            <Col lg={ 8 }>
                                <div className="search-results-header">
                                    <span className="search-results-title">
                                        <strong>4+ beds</strong> properties to rent in Central London
                                    </span>
                                    <div className="search-results-sort">
                                        { isMobileResolution && (
                                            <div>
                                                <span
                                                    className={ !showMapView ? 'selected' : '' }
                                                    onClick={ () => setShowMapView( false ) }
                                                >
                                                    List
                                                </span>
                                                <span className="divider">|</span>
                                                <span
                                                    className={ showMapView ? 'selected' : '' }
                                                    onClick={ () => setShowMapView( true ) }
                                                >
                                                    Map
                                                </span>
                                            </div>
                                        ) }
                                        <CustomDropDown
                                            name="sortby"
                                            defaultOption={ sortOptions[ 0 ] }
                                            options={ sortOptions }
                                            variant="small-black"
                                        // onChangeCallback={ onChange }
                                        />
                                    </div>
                                </div>
                                { ( !showMapView ) && (
                                    <>
                                        <PropertyCards
                                            propertyCardsData={ Properties }
                                            layout={ {
                                                lg: 6,
                                                sm: 6,
                                                xs: 12
                                            } }
                                            showLabel
                                        />
                                        <div>
                                            <CustomButton
                                                variant="outline"
                                                onClick={ () => {
                                                    console.log( "Button Clicked" );
                                                } }
                                                value="LOAD MORE"
                                            />
                                        </div>
                                    </>
                                ) }
                                { ( isMobileResolution && showMapView ) && (
                                    <div className="search-location-mobile">
                                        <PropertyLocation />
                                    </div>
                                ) }
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className="search-location-container">
                <PropertyLocation />
            </div>
        </div >
    )
}

export default SearchResults
